import * as React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { dimensions } from '../../styles/variables'
import _ from 'lodash'

interface EventProps {
  name: string
  image: File
  date: string
  venue: string
  url: string
}

const EventInner = styled.div`
  text-align: center;
  padding: 1rem;
  border-radius: 5%;
  line-height: ${dimensions.lineHeight.heading};

  h3 {
    font-size: ${dimensions.headingSizes.h5}rem;
    margin: 0;
  }

  img {
    max-width: 500px;
    max-height: 250px;
    object-fit: contain;
  }

  &:hover {
    background-color: #eee;
    transition: background-color 0.8s ease;
  }
`

const EventMeta = styled.div`
  padding: 0.5rem;
`

const Event: React.SFC<EventProps> = ({ name, image, date, venue, url }) => (
  <EventInner>
    <a href={url}>
      <div id={_.kebabCase(name.toLowerCase())}>
        <Img fixed={image} alt={name} />
        <EventMeta>
          <h3>{name}</h3>
          {date}
          <br />
          {venue}
        </EventMeta>
      </div>
    </a>
  </EventInner>
)

export default Event
