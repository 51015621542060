import * as React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { dimensions } from '../styles/variables'

import { ImageSharp, EventsJsonConnection } from '../graphql'
import Event from '../components/home/Event'

const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
  maxheight: 360px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.5));
`

const HeroH1 = styled.h1`
  padding: 1rem;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.15rem;
  font-weight: 700;
`

const Events = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`

const StyledH2 = styled.h2`
  width: 100%;
  margin: 1rem 0;
  font-size: ${dimensions.headingSizes.h1}rem;
`

interface IndexPageProps {
  data: {
    allEventsJson: {
      edges: EventsJsonConnection[]
    }
    file: {
      childImageSharp: {
        fluid: ImageSharp
      }
    }
  }
}

const IndexPage: React.SFC<IndexPageProps> = ({ data }) => (
  <IndexLayout>
    <Page>
      <HeroContainer>
        <Img
          style={{
            top: 0,
            left: '0',
            zIndex: -1,
            minHeight: '360px',
            position: 'fixed',
            width: '100%',
            maxWidth: '100vw'
          }}
          fluid={data.file.childImageSharp.fluid}
          alt="GopherCon Singapore 2017"
        />
        <HeroH1>Bringing tech communities together.</HeroH1>
      </HeroContainer>
      <Events>
        <StyledH2>Events</StyledH2>
        {data.allEventsJson.edges.map(({ node }) => (
          <Event
            key={_.kebabCase(node.name.toLowerCase())}
            name={node.name}
            image={node.logo.childImageSharp.fixed}
            date={node.date}
            venue={node.venue}
            url={node.url}
          />
        ))}
      </Events>
    </Page>
  </IndexLayout>
)

export default IndexPage

export const query = graphql`
  query HomeQuery {
    allEventsJson {
      edges {
        node {
          name
          logo {
            childImageSharp {
              fixed(width: 250, height: 250) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
          date(formatString: "D MMMM YYYY")
          venue
          url
        }
      }
    }
    file(relativePath: { eq: "hero-gopherconsg2017.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
